import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import LOGO from '../images/parivartan-transparant-logo.png';

export default function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const isActive = (path) => location.pathname === path;

  return (
    <nav className="bg-gray-50 fixed w-full z-20 top-0 start-0 border-b-2 border-black font-serif ">

          <div className=" flex flex-wrap items-center justify-between mx-auto p-2">
            {/* Logo and title */}
            <Link to="/" className="flex items-center space-x-3" onClick={closeMenu}>
              <div className=" flex items-center">
                <img
                  src={LOGO}
                  className="h-20 w-24 object-cover"
                  alt="Logo"
                />
              </div>
              <span className="self-center text-3xl font-bold whitespace-nowrap text-black" style={{fontFamily: 'Oleo Script'}}>
                Parivartan Services
              </span>
            </Link>
            {/* Mobile Menu Button */}
            <button
              type="button"
              onClick={toggleMenu}
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
              aria-controls="navbar-sticky"
              aria-expanded={isOpen}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
            {/* Career and Login Buttons - Always Visible on Desktop */}
            <div className="hidden md:flex md:order-2 space-x-3 ">
              <Link to="/career">
                <button
                  type="button"
                  className="text-white bg-first-500 hover:bg-first-600  font-bold rounded-xl text-md px-6 py-2 text-center dark:bg-first-600 dark:hover:bg-first-700"
                >
                  Career
                </button>
              </Link>
              <Link to="/login">
                <button
                  type="button"
                  className="text-white bg-first-600 hover:bg-first-500   font-bold rounded-xl text-md px-6 py-2 text-center dark:bg-first-600 dark:hover:bg-first-700 me-3"
                >
                  Login
                </button>
              </Link>
            </div>
            {/* Menu Links */}
            <div
              className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${
                isOpen ? '' : 'hidden'
              }`}
              id="navbar-sticky"
            >
              <ul className="flex flex-col p-6 md:p-0 mt-5 font-bold text-md  md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0  text-black">
                <li>
                  <Link
                    to="/"
                    className={`block py-2 px-3 rounded md:p-0 ${
                      isActive('/')
                        ? 'text-first-500 font-bold'
                        : 'hover:bg-gray-100 md:hover:bg-transparent md:hover:text-first-700'
                    }`}
                    onClick={closeMenu}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    className={`block py-2 px-3 rounded md:p-0 ${
                      isActive('/about')
                        ? 'text-first-500 font-bold'
                        : 'hover:bg-gray-100 md:hover:bg-transparent md:hover:text-first-700'
                    }`}
                    onClick={closeMenu}
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services"
                    className={`block py-2 px-3 rounded md:p-0 ${
                      isActive('/services')
                        ? 'text-first-500 font-bold'
                        : 'hover:bg-gray-100 md:hover:bg-transparent md:hover:text-first-700'
                    }`}
                    onClick={closeMenu}
                  >
                    Services
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className={`block py-2 px-3 rounded md:p-0 ${
                      isActive('/contact')
                        ? 'text-first-500 font-bold'
                        : 'hover:bg-gray-100 md:hover:bg-transparent md:hover:text-first-700'
                    }`}
                    onClick={closeMenu}
                  >
                    Contact Us
                  </Link>
                </li>
                {/* Career and Login Buttons Visible on Mobile View */}
                <li className="md:hidden">
                  <Link to="/career">
                    <button
                      type="button"
                      className="text-white bg-first-700 hover:bg-first-600 focus:outline-none  font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-first-600 dark:hover:bg-first-700 w-full mb-2"
                      onClick={closeMenu}
                    >
                      Career
                    </button>
                  </Link>
                </li>
                <li className="md:hidden">
                  <Link to="/login">
                    <button
                      type="button"
                      className="text-white bg-first-700 hover:bg-first-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-first-600 dark:hover:bg-first-700 w-full"
                      onClick={closeMenu}
                    >
                      Login
                    </button>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
    </nav>
  );
}
