import React from 'react';
import { FaFacebookF, FaLinkedinIn, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import LOGO from '../images/parivartan-transparant-logo.png';

const Footer = () => {
  return (
    <footer className="bg-gray-700 text-white font-serif ">
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className='text-center mb-4 ' style={{textAlign:'-webkit-center'}}>
            <img
                src={LOGO}
                className="h-28 w-36 object-cover rounded-3xl "
                alt="Logo"
              />
          </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">

          {/* About Section */}
          <div className='text-center'>
            <h2 className="text-lg font-semibold mb-2">About Parivartan Services</h2>
            <hr className="my-2 border-gray-500" />
            <p className="text-sm leading-relaxed text-justify italic">
            Parivartan Services is your trusted partner in career advancement. We specialize in providing job opportunities across various categories, including all private sectors. Our expert guidance and consultancy services empower individuals to achieve their professional goals and unlock new opportunities.
            </p>
          </div>

          {/* Quick Links Section */}
          <div className='text-center'>
            <h2 className="text-lg font-semibold mb-2">Quick Links</h2>
            <hr className="my-2 border-gray-500" />
            <ul className="space-y-2 text-md font-bold">
            <li>
                <a href="/career" className="hover:text-gray-400 text-blue-500">Career</a>
              </li>
              <li>
                <a href="/about" className="hover:text-gray-400 text-blue-500">About Us</a>
              </li>
              <li>
                <a href="/services" className="hover:text-gray-400 text-blue-500">Our Services</a>
              </li>
              <li>
                <a href="/contact" className="hover:text-gray-400 text-blue-500">Contact Us</a>
              </li>

            </ul>
          </div>

          {/* Contact Section */}
          <div className='text-center'>
            <h2 className="text-lg font-semibold mb-2">Contact Us</h2>
            <hr className="my-2 border-gray-500" />
            <p className="text-sm mb-2">
            <span className='font-bold'>Email : </span> <a href="mailto:info@parivartanservices.com" className="hover:text-gray-400">yuvrajss358@gmail.com</a>
            </p>
            <p className="text-sm">
              <span className='font-bold'> Address : </span> Main Road Yawal, Jalgaon, 425301
            </p>


            <div className="flex space-x-4 mt-6 justify-center">
                <a
                  href="https://www.facebook.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-10 h-10 flex justify-center items-center bg-blue-700 rounded-full hover:bg-blue-500 transition"
                  aria-label="Facebook"
                >
                  <FaFacebookF />
                </a>
                <a
                  href="https://www.linkedin.com/in/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-10 h-10 flex justify-center items-center bg-blue-500 rounded-full hover:bg-blue-400 transition"
                  aria-label="LinkedIn"
                >
                  <FaLinkedinIn />
                </a>
                <a
                  href="https://twitter.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-10 h-10 flex justify-center items-center bg-blue-400 rounded-full hover:bg-blue-300 transition"
                  aria-label="Twitter"
                >
                  <FaTwitter />
                </a>
                <a
                  href="https://wa.me/917350260560" 
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-10 h-10 flex justify-center items-center bg-green-600 rounded-full hover:bg-green-400 transition"
                  aria-label="WhatsApp"
                >
                  <FaWhatsapp />
                </a>
              </div>


          </div>
        </div>

        <hr className="my-6 border-gray-500" />

        <div className="flex flex-col md:flex-row justify-between items-center">
         

          <p className="text-sm text-right">
            &copy; {new Date().getFullYear()} Parivartan Services. All rights reserved.
          </p>

        </div>
      </div>
    </footer>
  );
};

export default Footer;
