import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { FiHome, FiUser, FiLogOut, FiAirplay } from 'react-icons/fi'; // Icons
import img from "../images/logo.jpg"; // Imported logo image

export default function Dashboard() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="min-h-screen flex bg-gray-100">
      {/* Sidebar */}
      <aside
        className={`bg-first-600 text-white transition-transform duration-300 ${
          isSidebarOpen ? 'w-64' : 'w-20'
        }`}
      >
        <div className="flex items-center justify-between p-4 bg-first-600">
          {/* Sidebar Logo - Conditional rendering based on isSidebarOpen */}
          {isSidebarOpen ? (
            <>
              <img
                src={img}
                alt="Parivartan Service Logo"
                className="h-8 rounded-lg"
              />
              <h2 className="text-sm font-bold ml-2">Parivartan Service</h2>
            </>
          ) : null}

          {/* Toggle button */}
          <button className="text-white ml-auto text-lg" onClick={toggleSidebar}>
            {isSidebarOpen ? '←' : '→'}
          </button>
        </div>

        {/* Sidebar Links */}
        <nav className="mt-10">
          <Link to="/admin/hiring" className="flex items-center px-4 py-3 text-lg hover:bg-first-700">
            <FiHome className="mr-3" />
            {isSidebarOpen && <span>Categories</span>}
          </Link>
          <Link to="/admin/allcandidates" className="flex items-center px-4 py-3 text-lg hover:bg-first-700">
            <FiUser className="mr-3" />
            {isSidebarOpen && <span>All Candidate</span>}
          </Link>
          <Link to="/admin/add_mcq" className="flex items-center px-4 py-3 text-lg hover:bg-first-700">
            <FiAirplay className="mr-3" />
            {isSidebarOpen && <span>Add MCQ</span>}
          </Link>
          <Link to="/login" className="flex items-center px-4 py-3 text-lg hover:bg-first-700">
            <FiLogOut className="mr-3" />
            {isSidebarOpen && <span>Logout</span>}
          </Link>
        </nav>
      </aside>

      {/* Content */}
  <Outlet/>
        {/* <Register /> */}
       
    
    </div>
  );
}
