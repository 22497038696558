import React from 'react';
import Hero from './Hero';
import AboutUs from './AboutUs';
import Services from './Services';
// import Contact from './Contact';
import Client from './Feedback';
import Navbar from './NavBar';
import JobSliderNotification from './JobSliderNotification';
import JobList from './JobList';

export default function Home() {
  return (
    <div>

      <Navbar />

      <Hero />
      <JobSliderNotification />

      <JobList />

      <AboutUs />

      <Services />

      <Client />


    </div>
  );
}
