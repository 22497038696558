import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axiosInstance from '../../services/AxiosInstance';
import './ApplyNow.css';

const ApplyNow = () => {
    const { jobTitle } = useParams();
    const {jobCategory} = useParams();
    const [decodedTitle, setDecodedTitle] = useState('');
    const [decodedJobCategory, setJobCategory] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        experience: '',
        skills: '',
        agree: false,
        resume: null,
        tenth: '',
        twelfthOrDiploma: '',
        graduation: '',
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [careerId, setCareerId] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (jobTitle) {
            const decoded = decodeURIComponent(jobTitle);
            setDecodedTitle(decoded);
        }
        if(jobCategory){
            const decoded = decodeURIComponent(jobCategory);
            setJobCategory(decoded);
            console.log(decoded);
        }
        
    }, [jobTitle,jobCategory]);

    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : name === 'resume' ? files[0] : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if all required fields are filled in
        if (!formData.name || !formData.email || !formData.mobile ) {
            setErrorMessage('Please fill in all required fields.');
            return;
        }

        setErrorMessage('');
        setSuccessMessage('');
        setIsLoading(true);

        // Prepare form data with file upload (resume)
        const submissionData = new FormData();
        submissionData.append('name', formData.name);
        submissionData.append('email', formData.email);
        submissionData.append('mobile', formData.mobile);
        submissionData.append('experience', formData.experience);
        submissionData.append('skills', formData.skills);
        submissionData.append('tenth', formData.tenth);
        submissionData.append('twelfthOrDiploma', formData.twelfthOrDiploma);
        submissionData.append('graduation', formData.graduation);
        submissionData.append('resume', formData.resume);
        submissionData.append('jobTitle', decodedTitle);
        submissionData.append('jobCategory', decodedJobCategory);

        try {
            const response = await axiosInstance.post('/backend/career', submissionData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            console.log(response);
            setSuccessMessage(`Successfully applied for ${decodedTitle}!`);
            setCareerId(response.data.id)
            setIsModalOpen(true);

        } catch (error) {
            console.error('Error during submission:', error); // Log for debugging
            setErrorMessage('An error occurred while submitting your application.');
        } finally {
            setIsLoading(false);
        }
    };

 const handleNavigateToTest = () => {
        setIsModalOpen(false);
        navigate('/mcq-test', {
            state: { id: careerId, title: decodedTitle },
        });
    };

    const handleNavigateToUpload = () => {
        setIsModalOpen(false);
        navigate('/upload-document', {
            state: { id: careerId, title: decodedTitle },
        });
    };

    return (
        <div className="max-w-2xl mx-auto p-6 mt-8 mb-8 bg-white shadow-lg rounded-lg border border-gray-200">
            <h1 className="text-3xl font-bold text-gray-900 mb-4 mt-6">Apply for {decodedTitle || 'Position'}</h1>
            <p className="text-md text-gray-700 mb-4">
                Thank you for your interest in the {decodedTitle || 'Position'} position. Please fill out the form below to apply.
            </p>
            {errorMessage && (
                <div className="mb-4 text-red-600">
                    {errorMessage}
                </div>
            )}
            {successMessage && (
                <div className="mb-4 text-green-600">
                    {successMessage}
                </div>
            )}
            <form className="space-y-4" onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded-lg p-3 shadow-sm focus:ring-2 focus:ring-first-500"
                    placeholder="Name"
                    required
                />
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded-lg p-3 shadow-sm focus:ring-2 focus:ring-first-500"
                    placeholder="Email"
                    required
                />
                <input
                    type="tel"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded-lg p-3 shadow-sm focus:ring-2 focus:ring-first-500"
                    placeholder="Mobile"
                    required
                />

                {/* 10th Percentage */}
                <input
                    type="text"
                    name="tenth"
                    value={formData.tenth}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded-lg p-3 shadow-sm focus:ring-2 focus:ring-first-500"
                    placeholder="10th Percentage"
                    
                />

                {/* 12th or Diploma Percentage */}
                <input
                    type="text"
                    name="twelfthOrDiploma"
                    value={formData.twelfthOrDiploma}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded-lg p-3 shadow-sm focus:ring-2 focus:ring-first-500"
                    placeholder="12th or Diploma Percentage"
                    
                />

                {/* Graduation Percentage */}
                <input
                    type="text"
                    name="graduation"
                    value={formData.graduation}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded-lg p-3 shadow-sm focus:ring-2 focus:ring-first-500"
                    placeholder="Graduation Percentage"
                    
                />

                <select
                    name="experience"
                    value={formData.experience}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded-lg p-3 shadow-sm focus:ring-2 focus:ring-first-500"
                    
                >
                    <option value="">Experience Level</option>
                    <option value="beginner">Beginner</option>
                    <option value="intermediate">Intermediate</option>
                    <option value="expert">Expert</option>
                </select>

                <textarea
                    name="skills"
                    value={formData.skills}
                    onChange={handleChange}
                    rows="4"
                    className="w-full border border-gray-300 rounded-lg p-3 shadow-sm focus:ring-2 focus:ring-first-500"
                    placeholder="Skills"
                    required
                />

                {/* Resume Upload */}
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="resume">
                        Upload Resume
                    </label>
                    <input
                        type="file"
                        name="resume"
                        id="resume"
                        accept=".pdf,.doc,.docx"
                        onChange={handleChange}
                        className="w-full border border-gray-300 rounded-lg p-3 shadow-sm focus:ring-2 focus:ring-first-500"
                        
                    />
                </div>

                <div className="flex items-center">
                    <input
                        type="checkbox"
                        name="agree"
                        id="agree"
                        checked={formData.agree}
                        onChange={handleChange}
                        className="h-5 w-5 text-first-600 focus:ring-first-500 border-gray-300 rounded"
                        required
                    />
                    <label className="ml-2 text-sm text-gray-600 cursor-pointer" htmlFor="agree">
                        I agree to the <Link to="#" className="text-first-500 hover:underline">terms and conditions</Link>.
                    </label>
                </div>

                <div className="flex justify-center">
                    <button
                        type="submit"
                        className="bg-first-500 text-white py-2 px-6 rounded-lg shadow-md hover:bg-first-600"
                        disabled={isLoading}
                    >
                        Submit Application
                    </button>
                </div>
            </form>

            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2>What would you like to do next?</h2>
                        <button onClick={handleNavigateToTest}>Take the Test</button>
                        <button onClick={handleNavigateToUpload}>Upload Documents</button>
                    </div>
                </div>
            )}

        </div>
    );
};

export default ApplyNow;
