import React, { useState } from 'react';

export default function Search({ onSearchChange }) {
  const [searchInput, setSearchInput] = useState('');

  // Handle input change to update local state
  const handleInputChange = (e) => {
    setSearchInput(e.target.value); // Update input value on change
  };

  // Handle form submission and call the passed onSearchChange prop function
  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent form's default behavior
    onSearchChange(searchInput); // Call the parent function with the search input value
  };

  return (
    <div className="">
      <form
        className="flex items-center max-w-4xl mx-auto mb-5"
        onSubmit={handleFormSubmit} // Submit form on "Enter" key press
      >
        {/* Search Input Field */}
        <div className="relative w-full">
          <label htmlFor="search" className="sr-only">Search</label>
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="text"
            id="search"
            value={searchInput}
            onChange={handleInputChange} // Update state on input change
            className="bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-first-500 focus:border-first-500 block w-full pl-10 p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-first-500 dark:focus:border-first-500"
            placeholder="Search by Job Title, Location or Industry..."
          />
        </div>

        {/* Search Button */}
        <button
          type="submit" // Triggers form submission on "Enter" or button click
          className="inline-flex items-center py-2.5 px-5 ml-2 text-sm font-medium text-white bg-first-700 rounded-lg border border-first-500 hover:bg-first-600 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-first-600 dark:hover:bg-first-600 dark:focus:ring-first-800"
        >
          <svg
            className="w-4 h-4 mr-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            />
          </svg>
          Search
        </button>
      </form>
    </div>
  );
}
