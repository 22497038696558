import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../services/AxiosInstance';

const CodingTestQuestion = () => {
    // const location = useLocation();
    // const testId = location.state?.id;
    // const jobTitle = location.state?.title;
    const navigate = useNavigate();

    // Extract testId and jobTitle from the location state
    // eslint-disable-next-line
    const [testId, setTestId] = useState(localStorage.getItem('testId') || null);
    // eslint-disable-next-line
    const [jobTitle, setJobTitle] = useState(localStorage.getItem('jobTitle') || null);
    const warningCountRef = useRef(0);


    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                warningCountRef.current += 1;

                if (warningCountRef.current === 1) {
                    alert('Warning: Please do not switch tabs during the test.');
                } else if (warningCountRef.current === 2) {
                    alert('Warning: Last warning if you change the tabs again your test is not submitted & redirected to home page.');
                } else if (warningCountRef.current >= 3) {
                    alert('You have switched tabs twice. Redirecting to the homepage.');
                    navigate('/'); // Redirect to homepage or any other route
                }
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [navigate]);




    useEffect(() => {
        // Remove testId and jobTitle from localStorage when the page is reloaded
        localStorage.removeItem('testId');
        localStorage.removeItem('jobTitle');
        // Check if testId and jobTitle are null, and navigate to the home page if true
        if (testId == null || jobTitle == null) {
            navigate("/");
        }
    }, [testId, jobTitle, navigate]);

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [timer, setTimer] = useState(2 * 60); // Set timer for 10 minutes (600 seconds)

    useEffect(() => {
        axiosInstance.get("/coding/question")
            .then(response => {
                const apiQuestions = response.data.map(q => ({
                    id: q.id,
                    question: q.question,
                }));
                setQuestions(apiQuestions);
                setAnswers(Array(apiQuestions.length).fill(""));
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching questions:', error);
                setErrorMessage('Failed to load questions. Please try again.');
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (timer === 0) {
            confirmSubmit(); // Auto-submit when timer reaches 0
        }
        const intervalId = setInterval(() => {
            setTimer(prev => prev - 1);
        }, 1000);

        return () => clearInterval(intervalId);
        // eslint-disable-next-line
    }, [timer]);

    const handleInputChange = (e) => {
        const newAnswers = [...answers];
        newAnswers[currentQuestionIndex] = e.target.value;
        setAnswers(newAnswers);
    };

    const handleNext = () => {
        if (!answers[currentQuestionIndex]) {
            alert("Please fill in the answer before moving to the next question.");
            return;
        }
        setCurrentQuestionIndex(prev => prev + 1);
    };


    const handleSubmit = () => {
        if (answers.includes("")) {
            alert("Please fill in all the answers before submitting.");
            return;
        }
        setIsModalOpen(true);
    };


    const confirmSubmit = async () => {
        const dataToSubmit = questions.map((question, index) => ({
            question: question.question,
            userCode: answers[index],
        }));

        axiosInstance.post(`/coding/answers/${testId}`, dataToSubmit)
            .then(response => {
                navigate('/upload-document', { state: { id: testId, title: jobTitle } });
            })
            .catch(error => {
                console.error('Error submitting answers:', error);
                setErrorMessage('Failed to submit the test. Please try again.');
            });
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const currentQuestion = questions[currentQuestionIndex];

    return (
        <div className="max-w-3xl m-5 mx-auto p-6 bg-white shadow-xl rounded-lg border border-gray-200">
            {loading ? (
                <div className="text-center">
                    <p className="text-lg font-medium text-gray-600">Loading questions...</p>
                </div>
            ) : errorMessage ? (
                <div className="text-center">
                    <p className="text-lg font-medium text-red-600">{errorMessage}</p>
                </div>
            ) : (
                <>
                    <div className="flex justify-between items-center mb-6">
                        <h1 className="text-4xl font-extrabold text-gray-800">
                            Question {currentQuestionIndex + 1} of {questions.length}
                        </h1>
                        <div className="text-2xl font-semibold text-red-600">
                            Time Remaining: {formatTime(timer)}
                        </div>
                    </div>
                    <div className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4 text-gray-700">{currentQuestion.question}</h2>
                        <textarea
                            autoFocus
                            value={answers[currentQuestionIndex]}
                            onChange={handleInputChange}
                            placeholder="Type your code here..."
                            rows="10"
                            required
                            className="w-full mt-4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 whitespace-pre"
                        />

                    </div>
                    <div className="flex justify-between items-center">
                        {currentQuestionIndex < questions.length - 1 ? (
                            <button
                                onClick={handleNext}
                                className="bg-blue-600 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-blue-700 transition duration-300 ease-in-out"
                            >
                                Next
                            </button>
                        ) : (
                            <button
                                onClick={handleSubmit}
                                className="bg-green-600 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-green-700 transition duration-300 ease-in-out"
                            >
                                Submit
                            </button>
                        )}
                    </div>
                </>
            )}

            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg p-6 max-w-sm w-full">
                        <h2 className="text-2xl font-bold mb-4 text-gray-800">Submit Your Test</h2>
                        <p className="mb-4 text-gray-600">Are you sure you want to submit your test?</p>
                        {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
                        <div className="flex justify-end space-x-4">
                            <button
                                onClick={closeModal}
                                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={confirmSubmit}
                                className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
                            >
                                Submit & Next Step Upload Document
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CodingTestQuestion;
