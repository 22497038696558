import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const McqTest = () => {
  const location = useLocation();
  const careerId = location.state?.id;
  const jobTitle = location.state?.title;
  const navigate = useNavigate();

  // State for holding MCQ questions
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    // Check if testId and jobTitle are null, and navigate to the homepage if true
    if (careerId == null && jobTitle == null) {
      navigate('/');
    }

    // Fetch MCQ questions from the backend
    const fetchQuestions = async () => {
      try {
        const response = await axios.get('/mcq/question');  // Make sure your backend API URL is correct
        setQuestions(response.data);  // Store questions in state
      } catch (error) {
        console.error('Error fetching questions:', error);
      }
    };

    fetchQuestions();
  }, [careerId, jobTitle, navigate]);

  const handleStartTest = () => {
    setTimeout(() => {
      navigate('/mcq-test', { state: { id: careerId, title: jobTitle } });
    });
  };

  return (
    <div className="flex items-center justify-center py-10 bg-gray-200">
      <div className="max-w-4xl w-full bg-white shadow-xl rounded-lg p-10">
        <h1 className="text-4xl font-bold text-center text-gray-900 mb-8">
          Instructions
        </h1>
        <div className="bg-yellow-100 p-6 rounded-lg mb-6 border-l-4 border-yellow-500">
          <h2 className="text-3xl font-semibold text-yellow-800 mb-4 text-center">
            Read Carefully
          </h2>
          <ul className="list-decimal list-inside text-gray-700 space-y-2">
            <li><span>Format:</span> The test consists of 20 multiple-choice questions (MCQs).</li>
            <li><span>Passing Score:</span> A minimum of 60% is required for selection.</li>
            <li><span>Time Limit:</span> The test must be completed within 40 minutes.</li>
            <li><span>Camera Access:</span> Allow camera access from settings before starting the test.</li>
            <li><span>Warning:</span> Don't change tabs during the exam, or it will be submitted.</li>
          </ul>
          <p className="text-center mt-2 text-green-700">All the best!</p>
        </div>

        <div className="flex justify-center mt-6">
          <Link
            onClick={handleStartTest}
            className="bg-gradient-to-r from-green-500 to-teal-400 text-white text-lg py-3 px-8 rounded-full shadow-lg hover:from-green-600 hover:to-teal-500 transform hover:scale-105 transition-transform duration-300"
          >
            Start Test
          </Link>
        </div>
      </div>

      {/* Render questions if they exist */}
      {questions.length > 0 && (
        <div className="mt-10">
          <h2 className="text-2xl font-bold">MCQ Questions</h2>
          <ul className="mt-4">
            {questions.map((question, index) => (
              <li key={index} className="mb-4">
                <p className="font-semibold">{index + 1}. {question.text}</p>
                <ul className="ml-4">
                  {question.options.map((option, i) => (
                    <li key={i}>
                      <label>
                        <input type="radio" name={`question-${index}`} value={option} />
                        {option}
                      </label>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default McqTest;
