import React from 'react';
import { MapPin, Briefcase, Users, ChevronRight } from 'lucide-react';

const ProfessionalJobCard = ({ job, openModal }) => {
  return (
    <div className="bg-white rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 flex flex-col h-full">
      <div className="p-6 border-b border-gray-200 flex-grow">
        <div className="flex justify-between items-start mb-4">
          <div>
            <h3 className="text-xl font-bold text-gray-900 mb-1">{job.title}</h3>
            <p className="text-base text-blue-600 font-semibold">{job.company}</p>
          </div>
          <span className="bg-green-100 text-green-800 text-xs font-semibold px-2.5 py-0.5 rounded-full">
            New
          </span>
        </div>
        <div className="grid grid-cols-1 gap-2 mb-4">
          <div className="flex items-center text-sm text-gray-600">
            <MapPin className="mr-2 h-4 w-4 text-blue-500" />
            {job.location}
          </div>
          <div className="flex items-center text-sm text-gray-600">
            <Briefcase className="mr-2 h-4 w-4 text-blue-500" />
            {job.experience}
          </div>
          <div className="flex items-center text-sm text-gray-600">
            <Users className="mr-2 h-4 w-4 text-blue-500" />
            {job.vacancies} vacancies
          </div>
        </div>
        
        <div>
          <h4 className="text-sm font-semibold text-gray-700 mb-2">Required Skills:</h4>
          <div className="flex flex-wrap gap-2 mb-4">
            {job.skills && job.skills.map((skill, index) => (
              <span key={index} className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
                {skill}
              </span>
            ))}
          </div>
        </div>
      </div>
      
      <div className="p-6 bg-gray-50 border-t border-gray-200">
        <button 
          onClick={() => openModal(job)} 
          className="w-full bg-first-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-md transition-colors duration-300 flex items-center justify-center"
        >
          Apply Now
          <ChevronRight className="ml-2 h-4 w-4" />
        </button>
      </div>
    </div>
  );
};

export default ProfessionalJobCard;
