import React, { useState, useEffect, useCallback } from 'react';
import img1 from '../images/p5.jpg';
import img2 from '../images/p4.jpg';
import img3 from '../images/place2.jpg';
import { Link } from 'react-router-dom';

export default function Hero() {
  const images = [img1, img2, img3];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = useCallback(() => {
    setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  }, [images.length]);

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  useEffect(() => {
    const interval = setInterval(nextImage, 3000);
    return () => clearInterval(interval);
  }, [nextImage]);  

  return (
    <div className="relative w-full h-[600px] font-serif">
      <div className="relative h-full overflow-hidden">
        <img
          src={images[currentImageIndex]}
          className="absolute block w-full h-full object-fill transition duration-700 ease-in-out top-0"
          alt="Carousel"
        />

        <div className="absolute inset-0 bg-black opacity-40" /> 

        <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center text-center text-slate-100">
          <h1 className="text-4xl md:text-6xl sm:text-xl font-bold mb-4 drop-shadow-lg font-yearbook" > 
            Connecting Talent with Opportunity
          </h1>
          <p className="text-lg md:text-xl sm:text-base mb-6 drop-shadow-lg font-yearbook"> 
            Your trusted recruitment partner for the best job placement services.
          </p>
          <div className="flex space-x-4">
            <Link to="/services" 
            className="inline-flex items-center border-2 text-white font-bold py-3 px-6 rounded-full hover:bg-first-700 bg-gradient-to-r from-first-500 to-first-600 transform hover:scale-105 transition-all duration-300 shadow-lg"
            >
                Hire Consultant
            </Link>
            <Link to="/career"
                className="inline-flex items-center border-2 border-white text-white font-bold py-3 px-6 rounded-full hover:text-first-600 hover:bg-white bg-transparent transform hover:scale-105 transition-all duration-300 shadow-lg"
            >
              Looking For Job
            </Link>
          </div>
        </div>
      </div>

      <div className="absolute flex space-x-3 bottom-6 left-1/2 transform -translate-x-1/2">
        {images.map((_, index) => (
          <button
            key={index}
            type="button"
            className={`w-3 h-3 rounded-full ${currentImageIndex === index ? 'bg-white' : 'bg-gray-300'}`}
            onClick={() => setCurrentImageIndex(index)}
          />
        ))}
      </div>

      <button
        onClick={prevImage}
        className="absolute top-1/2 left-0 z-30 flex items-center justify-center h-full px-4 transform -translate-y-1/2 cursor-pointer group focus:outline-none"
      >
        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-white">
          <svg className="w-4 h-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 10" fill="none">
            <path d="M5 1L1 5l4 4" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <span className="sr-only">Previous</span>
        </span>
      </button>

      <button
        onClick={nextImage}
        className="absolute top-1/2 right-0 z-30 flex items-center justify-center h-full px-4 transform -translate-y-1/2 cursor-pointer group focus:outline-none"
      >
        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-white">
          <svg className="w-4 h-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 10" fill="none">
            <path d="M1 9l4-4-4-4" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <span className="sr-only">Next</span>
        </span>
      </button>
    </div>
  );
}
