import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../services/AxiosInstance';
import { useNavigate } from 'react-router-dom/dist';
import { isLoggedIn } from "../../../auth";


const ShowCodingQuestions = () => {


    const navigate = useNavigate();


    useEffect(() => {
        if (!isLoggedIn()) {
            navigate("/login");
        }

    }, [navigate])

    const [codingQuestions, setCodingQuestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [questionToDelete, setQuestionToDelete] = useState(null);

    useEffect(() => {
        fetchCodingQuestions();
    }, []);

    const fetchCodingQuestions = async () => {
        setLoading(true);
        setError(null);
        setSuccessMessage('');
        try {
            const response = await axiosInstance.get('/coding/question');
            setCodingQuestions(response.data);
        } catch (err) {
            setError('Failed to fetch coding questions.');
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        if (questionToDelete) {
            setLoading(true);
            setError(null);
            setSuccessMessage('');
            try {
                await axiosInstance.delete(`/coding/question/${questionToDelete.id}`);
                setCodingQuestions(codingQuestions.filter(question => question.id !== questionToDelete.id));
                setSuccessMessage('Coding question deleted successfully.');
            } catch (err) {
                setError('Failed to delete the coding question.');
            } finally {
                setLoading(false);
                setIsModalOpen(false);
            }
        }
    };

    const openModal = (question) => {
        setQuestionToDelete(question);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setQuestionToDelete(null);
    };

    const handleUpdate = (id) => {
        // Implement update logic, e.g., show a modal or redirect to an update form
        // console.log(`Update Coding Question with ID: ${id}`);
        navigate("/admin/update-coding-question", { state: { id } })

    };

    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Coding Questions</h2>

            {loading && <p>Loading...</p>}
            {error && <p className="text-red-500">{error}</p>}
            {successMessage && <p className="text-green-500">{successMessage}</p>}

            {!loading && !error && codingQuestions.length > 0 && (
                <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
                    <thead>
                        <tr>
                            <th className="px-4 py-2 border-b">#</th>
                            <th className="px-4 py-2 border-b">Question</th>
                            <th className="px-4 py-2 border-b">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {codingQuestions.map((question, index) => (
                            <tr key={question.id}>
                                <td className="px-4 py-2 border-b">{index + 1}</td>
                                <td className="px-4 py-2 border-b">{question.question}</td>
                                <td className="px-4 py-2 border-b">
                                    <button
                                        onClick={() => handleUpdate(question.id)}
                                        className="bg-yellow-500 text-white px-2 py-1 rounded mr-2"
                                    >
                                        <i className="fa-solid fa-pencil"></i>
                                    </button>
                                    <button
                                        onClick={() => openModal(question)}
                                        className="bg-red-500 text-white px-2 py-1 rounded"
                                    >
                                        <i className="fa-solid fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            {!loading && !error && codingQuestions.length === 0 && <p>No coding questions found.</p>}

            {/* Confirmation Delete Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded shadow-lg">
                        <h3 className="text-lg font-bold mb-4">Confirm Delete</h3>
                        <p>Are you sure you want to delete this question?</p>
                        <div className="flex justify-end mt-4">
                            <button
                                onClick={closeModal}
                                className="bg-green-500 text-white px-4 py-2 rounded mr-2"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleDelete}
                                className="bg-red-500 text-white px-4 py-2 rounded"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ShowCodingQuestions;
