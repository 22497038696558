import React, { useState } from 'react';
import axiosInstance from '../../../services/AxiosInstance';

const AddMcqForm = () => {
    const [formData, setFormData] = useState({
        title: '',
        question: '',
        optionA: '',
        optionB: '',
        optionC: '',
        optionD: '',
        correctAnswerOption: '',
        correctAnswer: '',
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    
    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'correctAnswerOption') {
            setFormData({
                ...formData,
                correctAnswerOption: value,
                correctAnswer: formData[value], // Ensure correctAnswer is updated
            });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            const jobTitle = formData.title; // Using title as jobTitle
            const response = await axiosInstance.post(`/mcq/question/${jobTitle}`, formData);

            if (response.status === 200 || response.status === 201) {
                setSuccess(true);
                setFormData({
                    title: '',
                    question: '',
                    optionA: '',
                    optionB: '',
                    optionC: '',
                    optionD: '',
                    correctAnswerOption: '',
                    correctAnswer: '',
                });
            } else {
                setError('Failed to submit the form.');
            }
        } catch (err) {
            setError('An error occurred while submitting the form.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex items-center justify-center min-screen bg-gray-100 ml-8 px-8 py-2">
            <div className="p-10 bg-white shadow-lg rounded-lg w-1/3">
                <h2 className="text-2xl font-bold mb-6 text-center">Add MCQ</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">Job Title</label>
                        <select
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-lg"
                            required
                        >
                            <option value="" disabled>Select a Job title</option>
                            <option value="Frontend">Frontend Developer</option>
                            <option value="Backend">Backend Developer</option>
                            <option value="FullStack">Full Stack Developer</option>
                        </select>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">Question</label>
                        <textarea
                            name="question"
                            value={formData.question}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-lg whitespace-pre"
                            rows="4"
                            placeholder="Enter the question"
                            required
                        />
                    </div>

                    {['A', 'B', 'C', 'D'].map((option) => (
                        <div className="mb-4" key={option}>
                            <label className="block text-gray-700 font-semibold mb-2">Option {option}</label>
                            <input
                                type="text"
                                name={`option${option}`}
                                value={formData[`option${option}`]}
                                onChange={handleChange}
                                className="w-full p-2 border border-gray-300 rounded-lg"
                                placeholder={`Enter option ${option}`}
                                required
                            />
                        </div>
                    ))}

                    <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">Correct Answer</label>
                        <select
                            name="correctAnswerOption"
                            value={formData.correctAnswerOption}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-lg"
                            required
                        >
                            <option value="" disabled>Select the correct answer</option>
                            <option value="optionA">Option A</option>
                            <option value="optionB">Option B</option>
                            <option value="optionC">Option C</option>
                            <option value="optionD">Option D</option>
                        </select>
                    </div>

                    {formData.correctAnswerOption && (
                        <div className="mt-6 p-4 bg-green-100 text-green-700 rounded-lg">
                            <strong>Correct Answer:</strong> {formData.correctAnswer}
                        </div>
                    )}

                    <div className="text-center">
                        <button
                            type="submit"
                            className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
                            disabled={loading}
                        >
                            {loading ? 'Submitting...' : 'Submit'}
                        </button>
                    </div>
                </form>

                {success && (
                    <div className="mt-6 p-4 bg-green-100 text-green-700 rounded-lg">
                        MCQ successfully added!
                    </div>
                )}

                {error && (
                    <div className="mt-6 p-4 bg-red-100 text-red-700 rounded-lg">
                        {error}
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddMcqForm;
