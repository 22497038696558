import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const StartTest = () => {

    const location = useLocation();
    const testId = location.state?.id;
    const jobTitle = location.state?.title;
    const navigate = useNavigate();



    useEffect(() => {
        // Check if testId and jobTitle are null, and navigate to /career if true
        if (testId == null && jobTitle == null) {
            navigate("/");
        }
    }, [testId, jobTitle, navigate]); // Dependencies to re-run the effect if these values change


    const handleStartTest = () => {
        console.log(testId,jobTitle);
        
        // console.log("Test started");
        // Redirect to the test page or start the test logic here
        setTimeout(() => {
            navigate("/mcq-test", { state: {id: testId, title: jobTitle }});
        }, );
    };

    return (
        <div className="pt-2 flex items-center justify-center bg-gray-200 mt-3 mb-3">
            <div className="max-w-3xl w-full bg-white shadow-xl rounded-lg p-10 mt-4">
                <h1 className="text-3xl font-semibold text-center text-gray-900 mb-8">
                    Welcome to Parivartan Services
                </h1>
                <p className="text-lg text-gray-600 mb-6 text-center">
                    You will be redirect to the MCQ and Coding test.
                </p>
                <p className="text-lg text-gray-600 mb-6 text-center">Please complete both the parts to continue the selection process.</p>

                <div className="p-3 mb-2 bg-orange-300 rounded-lg">
                    <p className='text-gray-600 text-center text-lg' > Please keep handy soft copy of ypur documents(Degree certificate and Aadhar Card) to upload it after completion of test.</p>
                </div>
                <div className="bg-gray-100 p-6 rounded-lg mb-6">
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                        Rules and Regulations
                    </h2>
                    <ul className="list-disc list-inside text-gray-700 space-y-2">

                        <li>This test consist of 20 multiple choice question and 4 coding questions.</li>
                        <li>You have 2 hours and 10 minutes to complete the test.</li>
                        <li>Do not use any external resource during the test.</li>
                        <li>avoid chancing tab or copying answers, as this will result in automatic submission without warning.</li>
                        <li>Ensure a stable internate connection to prevent early submission. </li>

                    </ul>
                    <p className='text-center mt-2' > Good luck with your test!</p>
                </div>
                <div className="flex justify-center">
                    <Link
                        onClick={handleStartTest}
                        className="bg-gradient-to-r from-first-500 to-orange-400 text-white text-lg py-3 px-8 rounded-full shadow-lg hover:from-first-600 hover:to-first-500 transform hover:scale-105 transition-transform duration-300"
                    >
                        Start Test
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default StartTest;
