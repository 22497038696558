import React from 'react';

const JobSlider = () => {
  return (
    <nav className="bg-first-500 p-4"> {/* Test with bg-blue-500 */}
      <div className="overflow-hidden">
        <ul className="whitespace-nowrap animate-marquee flex space-x-8 text-white font-bold font-yearbook"> {/* Added Yearbook font */}
          <li>Full-time Employment</li>
          <li>Part-time Employment</li>
          <li>Contractual/Temporary Employment</li>
          <li>Internships</li>
          <li>Freelance/Consulting</li>
          <li>Apprenticeships</li>
          <li>Remote/Telecommuting Jobs</li>
          <li>On-call/Shift Work</li>
          <li>Gig Economy Jobs</li>
          <li>Seasonal Employment</li>
        </ul>
      </div>
    </nav>
  );
};

export default JobSlider;
