import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../../services/AxiosInstance';

const UploadDocument = () => {
    const location = useLocation();
    const careerId = location.state?.id;
    // const jobTitle = location.state?.title;
    const navigate = useNavigate();
    const [documents, setDocuments] = useState({
        sscCertificate: null,
        hscDiplomaCertificate: null,
        degree: null,
        aadhar: null,
        internship: null,
        experience: null,
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [message, setMessage] = useState('');

    const handleFileChange = (e, field) => {
        const file = e.target.files[0];
        const maxSize = 2 * 1024 * 1024; // 2 MB limit

        if (file && file.size > maxSize) {
            setMessage(`File size for ${field} should not exceed 2 MB.`);
            e.target.value = ''; // Clear the input
            setDocuments(prev => ({ ...prev, [field]: null }));
            setIsModalOpen(true);
            return;
        }

        setDocuments(prev => ({ ...prev, [field]: file }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('sscCertificate', documents.sscCertificate);
        formData.append('hscDiplomaCertificate', documents.hscDiplomaCertificate);
        formData.append('degree', documents.degree);
        formData.append('aadhar', documents.aadhar);
        formData.append('internship', documents.internship);
        formData.append('experience', documents.experience);

        try {
            const response = await axiosInstance.post(`/backend/document/upload/${careerId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log(response);
            setMessage('Your application was submitted successfully.');
            setIsModalOpen(true);
        } catch (error) {
            setMessage('There was an error submitting your application. Please try again.');
            setIsModalOpen(true);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        if (message.includes('successfully')) {
            navigate("/"); // Navigate to home or another page
        }
    };

    return (
        <div className="max-w-4xl mx-auto mt-16 p-5 bg-white shadow-lg rounded-lg border border-gray-200">
            <h2 className="text-3xl font-bold text-gray-800 p-2 bg-first-600 rounded-lg mb-2 text-center">Upload Your Documents</h2>
        
            <p className="text-center text-gray-500 mb-4">Please upload your documents to proceed with your application.</p>
            <form onSubmit={handleSubmit} className="space-y-6">
                {[
                    { label: "10th Certificate", field: "sscCertificate",optional: true },
                    { label: "12th Certificate/Diploma", field: "hscDiplomaCertificate", optional: true },
                    { label: "Highest Degree Certificate", field: "degree", optional: true },
                    { label: "Aadhaar Card", field: "aadhar" },
                    { label: "Internship Letter", field: "internship", optional: true },
                    { label: "Experience Letter", field: "experience", optional: true },
                ].map(({ label, field, optional }) => (
                    <div key={field} className="flex justify-between items-center">
                        <label className="text-lg font-medium text-gray-700">
                            {label} {optional && <span className="text-sm text-gray-500">(Optional)</span>}
                        </label>
                        <input
                            type="file"
                            onChange={(e) => handleFileChange(e, field)}
                            required={!optional}
                            className="w-1/2 text-gray-600 p-1 border border-gray-300 rounded-md cursor-pointer"
                        />
                    </div>
                ))}
                <div className="text-center">
                    <button
                        type="submit"
                        className="bg-first-500 text-white py-3 px-8 rounded-lg shadow-lg hover:bg-first-700 transition duration-300"
                    >
                        Submit
                    </button>
                </div>
            </form>

            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg p-6 shadow-lg max-w-md w-full">
                        <h3 className="text-2xl font-bold text-gray-800 mb-4">{message.includes('successfully') ? 'Submitted Successfully' : 'Submission Error'}</h3>
                        <p className="text-gray-600 mb-6">{message}</p>
                        <button
                            onClick={closeModal}
                            className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UploadDocument;
