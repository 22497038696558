import React from 'react';
import { FaCode, FaChartLine, FaBuilding, FaMedkit, FaBullhorn, FaWrench, FaChalkboardTeacher, FaIndustry, FaShippingFast, FaHotel, FaStore, FaUserTie, FaNewspaper } from 'react-icons/fa';

const categories = [
  {
    title: 'Technology & IT',
    descriptionList: [
      {
        subCategory: 'Software Development',
        category:'softwaredevelopment',
        jobs: [
          'frontend',
          'backend',
          'fullstack',
        ]
      },
      {
        subCategory: 'Data Science',
        category:'datascience',
        jobs: [
          'Data Scientist',
          'Data Analyst',
          'Machine Learning Engineer',
        ]
      },
      {
        subCategory: 'Cloud & DevOps',
        category:'clouddevops',
        jobs: [
          'Cloud Engineer (AWS, Azure, Google Cloud)',
          'DevOps Engineer',
          'Site Reliability Engineer (SRE)',
        ]
      },
      {
        subCategory: 'Cybersecurity',
        category:'cybersecurity',
      
        jobs: [
          'Cybersecurity Analyst',
          'Security Engineer',
          'Information Security Specialist',
        ]
      },
      {
        subCategory: 'Network & System Administration',
        category:'networksystemadministration',
        jobs: [
          'Network Engineer',
          'System Administrator',
          'IT Support Specialist',
        ]
      },
      {
        subCategory: 'Design & UI/UX',
        category:'designuiux',
        jobs: [
          'UX/UI Designer',
          'Web Designer',
          'Graphic Designer',
        ]
      }
    ],
    icon: <FaCode size={50} className="text-blue-500" />,
  },
  {
    title: 'Finance & Accounting',
    descriptionList: [
      {
        subCategory: 'Accounting',
        jobs: [
          'Chartered Accountant (CA)',
          'Tax Consultant',
          'Auditor',
        ]
      },
      {
        subCategory: 'Finance',
        jobs: [
          'Financial Analyst',
          'Investment Banker',
          'Loan Officer',
        ]
      },
      {
        subCategory: 'Payroll & Billing',
        jobs: [
          'Payroll Specialist',
          'Billing Coordinator',
          'Credit Analyst',
        ]
      }
    ],
    icon: <FaChartLine size={50} className="text-green-500" />,
  },
  {
    title: 'Healthcare',
    descriptionList: [
      {
        subCategory: 'Medical & Nursing',
        jobs: [
          'Registered Nurse',
          'Physician/Doctor (MBBS, MD)',
          'Paramedic',
        ]
      },
      {
        subCategory: 'Allied Health Professionals',
        jobs: [
          'Physical Therapist',
          'Occupational Therapist',
          'Pharmacist',
        ]
      },
      {
        subCategory: 'Healthcare Administration',
        jobs: [
          'Healthcare Administrator',
          'Medical Transcriptionist',
          'Lab Technician',
        ]
      }
    ],
    icon: <FaMedkit size={50} className="text-red-500" />,
  },
  {
    title: 'Education',
    descriptionList: [
      {
        subCategory: 'Teaching',
        jobs: [
          'Primary School Teacher',
          'Secondary School Teacher',
          'College/University Professor',
        ]
      },
      {
        subCategory: 'Administration',
        jobs: [
          'School Administrator',
          'Curriculum Developer',
          'Educational Consultant',
        ]
      },
      {
        subCategory: 'Support Roles',
        jobs: [
          'Tutor',
          'Teaching Assistant',
          'Admissions Counselor',
        ]
      }
    ],
    icon: <FaChalkboardTeacher size={50} className="text-yellow-500" />,
  },
  {
    title: 'Sales & Marketing',
    descriptionList: [
      {
        subCategory: 'Sales',
        jobs: [
          'Sales Executive',
          'Business Development Executive',
          'Sales Manager',
        ]
      },
      {
        subCategory: 'Marketing',
        jobs: [
          'Digital Marketing Specialist',
          'Content Writer',
          'Social Media Manager',
        ]
      },
      {
        subCategory: 'Advertising',
        jobs: [
          'SEO Specialist',
          'PPC Campaign Manager',
          'Marketing Analyst',
        ]
      }
    ],
    icon: <FaBullhorn size={50} className="text-purple-500" />,
  },
  {
    title: 'Engineering',
    descriptionList: [
      {
        subCategory: 'Mechanical Engineering',
        jobs: [
          'Mechanical Engineer',
          'HVAC Engineer',
          'CAD Technician',
        ]
      },
      {
        subCategory: 'Electrical Engineering',
        jobs: [
          'Electrical Engineer',
          'Power Systems Engineer',
          'Control Systems Engineer',
        ]
      },
      {
        subCategory: 'Civil Engineering',
        jobs: [
          'Civil Engineer',
          'Structural Engineer',
          'Construction Manager',
        ]
      },
      {
        subCategory: 'Other Engineering Fields',
        jobs: [
          'Aerospace Engineer',
          'Chemical Engineer',
          'Robotics Engineer',
        ]
      }
      
    ],
    icon: <FaWrench size={50} className="text-gray-500" />,
  },
  {
    title: 'Legal',
    icon: <FaChalkboardTeacher size={50} className="text-yellow-500" />,
    descriptionList: [
      {
        subCategory: 'Law',
        jobs: [
          'Lawyer/Advocate',
          'Paralegal',
          'Legal Secretary',
        ]
      },
      {
        subCategory: 'Corporate Legal',
        jobs: [
          'Corporate Counsel',
          'Legal Analyst',
          'Contract Manager',
        ]
      },
      {
        subCategory: 'Support Roles',
        jobs: [
          'Legal Researcher',
          'Court Clerk',
          'Compliance Officer',
        ]
      }
    ]
  },
  {
    title: 'Manufacturing & Production',
    icon: <FaIndustry size={50} className="text-blue-500" />,
    descriptionList: [
      {
        subCategory: 'Production Management',
        jobs: [
          'Production Manager',
          'Plant Supervisor',
          'Operations Manager',
        ]
      },
      {
        subCategory: 'Quality Assurance',
        jobs: [
          'Quality Control Inspector',
          'Production Technician',
          'QA Engineer',
        ]
      },
      {
        subCategory: 'Maintenance',
        jobs: [
          'Maintenance Technician',
          'Equipment Operator',
          'Machine Mechanic',
        ]
      }
    ]
  },
  {
    title: 'Logistics & Supply Chain',
    icon: <FaShippingFast size={50} className="text-green-500" />,
    descriptionList: [
      {
        subCategory: 'Supply Chain Management',
        jobs: [
          'Supply Chain Manager',
          'Procurement Specialist',
          'Inventory Controller',
        ]
      },
      {
        subCategory: 'Logistics & Transportation',
        jobs: [
          'Logistics Coordinator',
          'Transport Manager',
          'Freight Forwarder',
        ]
      },
      {
        subCategory: 'Warehouse Operations',
        jobs: [
          'Warehouse Manager',
          'Shipping and Receiving Clerk',
          'Forklift Operator',
        ]
      }
    ]
  },
  {
    title: 'Hospitality & Travel',
    icon: <FaHotel size={50} className="text-purple-500" />,
    descriptionList: [
      {
        subCategory: 'Hospitality',
        jobs: [
          'Hotel Manager',
          'Concierge',
          'Housekeeper',
        ]
      },
      {
        subCategory: 'Food & Beverage',
        jobs: [
          'Chef/Cook',
          'Bartender',
          'Food and Beverage Manager',
        ]
      },
      {
        subCategory: 'Travel',
        jobs: [
          'Travel Agent',
          'Tour Guide',
          'Event Planner',
        ]
      }
    ]
  },
  {
    title: 'Retail',
    icon: <FaStore size={50} className="text-red-500" />,
    descriptionList: [
      {
        subCategory: 'Store Operations',
        jobs: [
          'Store Manager',
          'Sales Associate',
          'Floor Supervisor',
        ]
      },
      {
        subCategory: 'Inventory & Merchandising',
        jobs: [
          'Inventory Specialist',
          'Merchandiser',
          'Visual Merchandiser',
        ]
      },
      {
        subCategory: 'Loss Prevention',
        jobs: [
          'Loss Prevention Specialist',
          'Security Officer',
          'Risk Manager',
        ]
      }
    ]
  },
  {
    title: 'Human Resources (HR)',
    icon: <FaUserTie size={50} className="text-orange-500" />,
    descriptionList: [
      {
        subCategory: 'HR Management',
        jobs: [
          'HR Manager',
          'HR Generalist',
          'Talent Acquisition Specialist',
        ]
      },
      {
        subCategory: 'Payroll & Benefits',
        jobs: [
          'Payroll Administrator',
          'Compensation & Benefits Analyst',
          'Employee Relations Specialist',
        ]
      },
      {
        subCategory: 'Training & Development',
        jobs: [
          'Training Coordinator',
          'Learning & Development Manager',
          'HR Coordinator',
        ]
      }
    ]
  },
  {
    title: 'Administration',
    icon: <FaBuilding size={50} className="text-gray-500" />,
    descriptionList: [
      {
        subCategory: 'Administrative Roles',
        jobs: [
          'Administrative Assistant',
          'Executive Assistant',
          'Office Manager',
        ]
      },
      {
        subCategory: 'Clerical & Data Entry',
        jobs: [
          'Data Entry Clerk',
          'Secretary',
          'Receptionist',
        ]
      },
      {
        subCategory: 'Virtual & Remote Assistance',
        jobs: [
          'Virtual Assistant',
          'Personal Assistant',
          'Records Manager',
        ]
      }
    ]
  },
  {
    title: 'Media & Communication',
    icon: <FaNewspaper size={50} className="text-indigo-500" />,
    descriptionList: [
      {
        subCategory: 'Journalism & Public Relations',
        jobs: [
          'Journalist',
          'PR Specialist',
          'News Anchor',
        ]
      },
      {
        subCategory: 'Content Creation',
        jobs: [
          'Content Writer',
          'Copywriter',
          'Video Editor',
        ]
      }
    ]
  }
];


export default categories;