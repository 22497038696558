import React from 'react';
import img1 from "../images/place.jpg";
import img2 from "../images/place1.jpg";
import img3 from "../images/place2.jpg";

export default function AboutUs() {
  return (
    <div className="nav">
      {/* About Us Section */}
      <section
        id="about"
        className="py-16 bg-white-100 pt-24 font-yearbook" // Apply custom font class here
        style={{
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold text-gray-800 mb-8">About Us</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Mission Card */}
            <div className="bg-white p-6 shadow-lg flex flex-col items-center text-center">
              <img className="mb-4 rounded-lg w-full h-72 object-cover" src={img2} alt="Our Mission" />
              <h3 className="text-2xl font-semibold mb-2">Our Mission</h3>
              <p className="text-gray-600">
                Empowering individuals and communities by providing resources and opportunities for a better life.
              </p>
              <button
                className="mt-4 inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-first-600 rounded-lg hover:bg-blue-800"
                onClick={() => alert('Learn more about Our Mission!')}
              >
                Learn More
                <svg
                  className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                </svg>
              </button>
            </div>

            {/* Vision Card */}
            <div className="bg-white p-6 shadow-lg flex flex-col items-center text-center">
              <img className="mb-4 rounded-lg w-full h-72 object-cover" src={img3} alt="Our Vision" />
              <h3 className="text-2xl font-semibold mb-2">Our Vision</h3>
              <p className="text-gray-600">
                A world where everyone has equal access to education, healthcare, and basic needs.
              </p>
              <button
                className="mt-4 inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-first-600 rounded-lg hover:bg-blue-800"
                onClick={() => alert('Learn more about Our Vision!')}
              >
                Learn More
                <svg
                  className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                </svg>
              </button>
            </div>

            {/* Values Card */}
            <div className="bg-white p-6 shadow-lg flex flex-col items-center text-center">
              <img className="mb-4 rounded-lg w-full h-72 object-cover" src={img1} alt="Our Values" />
              <h3 className="text-2xl font-semibold mb-2">Our Values</h3>
              <p className="text-gray-600">
                Transparency, compassion, and dedication drive us to achieve our goals.
              </p>
              <button
                className="mt-4 inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-first-600 rounded-lg hover:bg-blue-800"
                onClick={() => alert('Learn more about Our Values!')}
              >
                Learn More
                <svg
                  className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
