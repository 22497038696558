import React, { useState } from 'react';
import JobCard from './JobCard';
import { jobData } from './jobData';
import Search from './Search';

export default function AllJobs() {
  // State to store the search query
  const [searchQuery, setSearchQuery] = useState('');

  // Function to handle search query change
  const handleSearchChange = (query) => {
    setSearchQuery(query.toLowerCase()); // Convert query to lowercase for case-insensitive search
  };

  // Filter jobs based on search query
  const filteredJobs = jobData.filter((job) => {
    // Use optional chaining to safely access properties
    return (
      job?.title?.toLowerCase().includes(searchQuery) ||
      job?.location?.toLowerCase().includes(searchQuery) ||
      job?.industry?.toLowerCase().includes(searchQuery)
    );
  });

  return (
    <div className="container mx-auto p-4 font-yearbook"> {/* Apply custom font class here */}
      {/* Pass handleSearchChange to Search component */}
      <Search onSearchChange={handleSearchChange} />
      <h2 className="text-3xl font-bold text-center mb-8 mt-8">All Jobs</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
        {/* Render filtered jobs */}
        {filteredJobs.length > 0 ? (
          filteredJobs.map((job, index) => (
            <JobCard key={index} job={job} />
          ))
        ) : (
          <p className="col-span-3 text-center text-lg">No jobs found.</p>
        )}
      </div>
    </div>
  );
}
