import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../services/AxiosInstance';

export default function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post('/backend/auth/login', { email, password });
      console.log(response);
      if (response.data.jwtToken) {
        localStorage.setItem('token',response.data.jwtToken);
        navigate('/admin/dashboard'); 
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      alert('Login failed. Please try again.');
      console.error(error);
    }
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center bg-gradient-to-r from-first-700 via-first-500 to-first-700"
      
    >
      <div className="bg-white p-8 rounded-lg shadow-lg w-96 " >
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Welcome to Login</h2>
        <form onSubmit={handleSubmit}>
          {/* Email Input */}
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-indigo-200"
              placeholder="Enter your email"
              required
            />
          </div>

          {/* Password Input */}
          <div className="mb-6">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-indigo-200"
              placeholder="Enter your password"
              required
            />
          </div>

          {/* Login Button */}
          <div className="flex items-center justify-between mb-6">
            <button
              type="submit"
              className="w-full focus:outline-none focus:ring  bg-first-600 text-white py-2 px-4 rounded-lg hover:bg-first-600   focus:ring-gray-200"
            >
              Login
            </button>
          </div>

          {/* Forgot Password Link */}
          <div className="text-center mt-4">
            <button
              className="text-indigo-600 hover:underline focus:outline-none"
              onClick={() => alert('Redirect to Forgot Password')}
            >
              Forgot Password?
            </button>
          </div>
        </form>

      
        
      </div>
    </div>
  );
}
