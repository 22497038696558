import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-solid-svg-icons';

export default function QuestionIcon() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Handle the message submission logic here
    console.log('Message submitted:', message);
    // Simulate async operation
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setMessage('');
    setModalOpen(false);
    setIsLoading(false);
  };

  return (
    <div className="fixed bottom-4 right-4 z-30">
      <button
        onClick={handleClick}
        className="bg-first-600 text-white p-3 rounded-full shadow-lg hover:bg-first-700 transition duration-300 border-2"
      >
        <FontAwesomeIcon icon={faMessage} size="lg" />
      </button>

      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center px-auto py-auto"
          onClick={handleClose}
        >
          <div
            className="bg-white rounded-lg p-6 w-96 sm:w-2/3 md:w-1/2 lg:w-1/3 " // Adjust modal width based on screen size
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
          >
            <h2 className="text-xl font-bold mb-4 text-center">Ask Me a Question</h2>
            <form onSubmit={handleSubmit}>
              <textarea
                className="w-full h-24 border border-gray-300 rounded-lg p-2 mb-4"
                placeholder="Enter your message here..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>
              <div className="flex justify-end">
                <button
                  type="button"
                  className="mr-2 bg-gray-300 text-gray-700 px-4 py-2 rounded sm:text-xs"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={` bg-first-600 text-white px-4 py-2 rounded hover:bg-first-700 transition duration-300 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={isLoading}
                >
                  {isLoading ? 'Submitting...' : 'Submit'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
