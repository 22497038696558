import React, { useState, useEffect } from "react";
import axiosInstance from "../../services/AxiosInstance";
import { FaEye } from "react-icons/fa";

export default function Register() {
  const [candidates, setCandidates] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    fetchCandidates();
  }, []);

  const fetchCandidates = async () => {
    try {
      const response = await axiosInstance.get("/career");
      setCandidates(response.data);
    } catch (error) {
      console.error("Error fetching candidates:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/career/${id}`);
      setCandidates(candidates.filter(candidate => candidate.id !== id));
    } catch (error) {
      console.error("Error deleting candidate:", error);
    }
  };

  const filteredData = candidates.filter((candidate) =>
    ["name", "email", "mobile", "skills"].some((field) =>
      candidate[field]?.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const DocumentLink = ({ docId, label }) => (
    docId ? (
      <a
        href={`${axiosInstance.defaults.baseURL}/backend/document/${docId}`}
        
        target="_blank"
        rel="noopener noreferrer"
        className="text-indigo-600 hover:text-indigo-900 flex items-center justify-center"
      >
        <FaEye /> {label}
      </a>
    ) : (
      <span className="text-gray-500">No document</span>
    )
  );

  return (
    <div className="flex-1 px-2 py-8 rounded-lg overflow-hidden bg-white">
      <div className="p-8">
        <h1 className="text-3xl font-bold mb-6 text-center">Candidate List</h1>

        <form className="max-w-md mx-auto mb-4 bg-white">
          <div className="relative">
            <input
              type="search"
              className="block w-full p-4 text-sm text-gray-900 border border-gray-300 rounded-lg"
              placeholder="Search by Name, Email, Phone, or Skill..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              required
            />
          </div>
        </form>

        {/* Table container with scroll */}
        <div className="overflow-x-auto rounded-lg  overflow-y-auto">
          <table className="bg-white w-full">
            <thead>
              <tr>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Actions</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Name</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Email</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Phone</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Experience</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Job Title</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Skills</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Resume</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Aadhar Card</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">10th Certificate</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">12th/Diploma Certificate</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Degree</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Internship Letter</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Experience Letter</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredData.length ? (
                filteredData.map((candidate, index) => (
                  <tr key={candidate.id}>
                    <td className="px-6 py-4 text-gray-900">{index + 1}</td>
                    <td className="px-6 py-4 text-gray-500">{candidate.name}</td>
                    <td className="px-6 py-4 text-gray-500">{candidate.email}</td>
                    <td className="px-6 py-4 text-gray-500">{candidate.mobile}</td>
                    <td className="px-6 py-4 text-gray-500">{candidate.experience}</td>
                    <td className="px-6 py-4 text-gray-500">{candidate.jobTitle}</td>
                    <td className="px-6 py-4 text-gray-500">{candidate.skills}</td>
                    <td className="px-6 py-4"><DocumentLink docId={candidate.document?.resume} label="Resume" /></td>
                    <td className="px-6 py-4"><DocumentLink docId={candidate.document?.aadhar} label="Aadhar" /></td>
                    <td className="px-6 py-4"><DocumentLink docId={candidate.document?.sscCertificate} label="10th Cert" /></td>
                    <td className="px-6 py-4"><DocumentLink docId={candidate.document?.hscDiplomaCertificate} label="12th/Diploma" /></td>
                    <td className="px-6 py-4"><DocumentLink docId={candidate.document?.degree} label="Degree" /></td>
                    <td className="px-6 py-4"><DocumentLink docId={candidate.document?.internship} label="Internship" /></td>
                    <td className="px-6 py-4"><DocumentLink docId={candidate.document?.experience} label="Experience" /></td>
                    <td className="px-6 py-4">
                      <button onClick={() => handleDelete(candidate.id)} className="text-white hover:text-red-800 bg-red-700 ml-2 p-1 rounded-xl">Delete</button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="15" className="text-center py-4 text-gray-500 italic">
                    No candidates found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
