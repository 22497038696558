import React from 'react';
import IMAGES from '../images/i1.webp';
import IMAGES1 from '../images/i2.jpeg';

export default function Client() {
  return (
    <div>
      <section id="testimonials" className="py-16 bg-gray-100">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-12 text-gray-800 font-yearbook"> {/* Apply Yearbook font */}
            What Our Clients Say
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Testimonial Card 1 */}
            <div className="p-8 bg-white rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300">
              <p className="text-gray-700 italic mb-4 font-yearbook"> {/* Apply Yearbook font */}
                "ConsultancyCo helped us find the right candidates quickly and efficiently. Their attention to detail and industry knowledge is second to none."
              </p>
              <h4 className="text-xl font-bold text-first-600 flex items-center justify-center font-yearbook"> {/* Apply Yearbook font */}
                <img src={IMAGES} className="h-8 w-8 mr-1 inline-block rounded-full" alt="Logo" />
                Vishal Sabale, CEO of Corp
              </h4>
            </div>

            {/* Testimonial Card 2 */}
            <div className="p-8 bg-white rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300">
              <p className="text-gray-700 italic mb-4 font-yearbook"> {/* Apply Yearbook font */}
                "The career guidance and resume tips I received helped me land my dream job in no time. I highly recommend their services!"
              </p>
              <h4 className="text-xl font-bold text-first-600 flex items-center justify-center font-yearbook"> {/* Apply Yearbook font */}
                <img src={IMAGES1} className="h-8 w-8 mr-1 inline-block rounded-full" alt="Logo" />
                Capital, Marketing Manager
              </h4>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
