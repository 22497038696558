import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import categories from './categories';
// import axiosInstance from '../../services/AxiosInstance';

const HiringPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // Function to handle card click and navigate to the job details page
  const handleCardClick = async (categoryTitle, subCategory) => {
    setLoading(true);
    setError(null);

    try {
      // const response = await axiosInstance.get(`/career/job/${subCategory}`);
      // const jobData = response.data;
      // console.log(jobData);     

      // Navigate to job details with state, or you could store in context if preferred
      navigate(`/admin/job/${subCategory}`);
    } catch (err) {
      setError('Error navigating .');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4">
      <div className="max-w-7xl mx-auto px-4">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-12 font-yearbook">
          Job Categories
        </h1>

        {error && <p className="text-center text-red-500">{error}</p>}

        {/* Grid for job categories */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8">
          {categories.map((category, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 cursor-pointer"
            >
              {/* Icon section */}
              <div className="flex justify-center mb-4">
                {category.icon}
              </div>

              {/* Category Title */}
              <h2 className="text-2xl font-semibold text-center text-gray-800 mb-2 font-yearbook">
                {category.title}
              </h2>

              {/* Subcategories and Jobs */}
              {category.descriptionList.map((item, idx) => (
                <div 
                  key={idx} 
                  className="border-2 border-first-600 shadow-md hover:shadow-2xl hover:border-first-700 transition-all duration-300 p-4 mb-4 rounded-md"
                  onClick={() => handleCardClick(category.title, item.subCategory)}
                >
                  <h3 className="text-xl font-semibold text-left text-gray-700 mb-2 font-yearbook">
                    {item.subCategory}
                  </h3>
                  <ul className="text-gray-600 text-left font-yearbook list-disc list-inside">
                    {item.jobs.map((job, jobIdx) => (
                      <li key={jobIdx} className="py-1">{job}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ))}
        </div>

        {loading && <p className="text-center text-gray-500">Loading...</p>}
      </div>
    </div>
  );
};

export default HiringPage;