import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import JobCard from './JobCard';
import { jobData } from './jobData';

export default function JobList() {
  const [selectedJob, setSelectedJob] = useState(null);

  // Function to open the modal with the selected job
  const openModal = (job) => {
    setSelectedJob(job);
    console.log('Modal opened for job:', job); // Debugging log to check if modal is opened
  };

  // Get the first 3 recommended jobs
  const recommendedJobs = jobData.filter((job) => job.recommended).slice(0, 3);
  
  // Get the first 3 other jobs
  const otherJobs = jobData.filter((job) => !job.recommended).slice(0, 3);
  
  // Combine both arrays (optional, depending on your needs)
  const displayedJobs = [...recommendedJobs, ...otherJobs];

  return (
    <div className="container mx-auto  p-4 bg-slate-300">
      <h1 className="text-4xl font-bold text-center mb-8 mt-8 font-serif italic">Latest Job Openings</h1> {/* Added Yearbook font */}

      {/* Display only 3 jobs */}
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-8">
        {displayedJobs.map((job, index) => (
          <JobCard key={index} job={job} openModal={openModal} />
        ))}
      </div>

      {/* View More button with hover effect */}
      <div className="text-center mt-16 mb-6">
        <Link 
          to="/career" 
          className="bg-blue-700 text-white text-xl py-3 px-6 rounded-xl hover:bg-blue-900 transition-all duration-300 hover:py-4 hover:px-8 font-serif" // Added Yearbook font
        >
          View More Jobs
        </Link>
      </div>

      {/* Modal rendering logic */}
      {selectedJob && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white rounded-lg p-8 max-w-lg mx-auto relative">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-2xl font-bold font-yearbook">{selectedJob.title}</h3> {/* Added Yearbook font */}
              <button
                onClick={() => setSelectedJob(null)}
                className="text-2xl text-rose-600"
              >
                &times;
              </button>
            </div>
            <hr className="border-t border-blue-500 my-4" />

            <div className="mb-4">
              <strong className="font-yearbook">Profile Required:</strong> {/* Added Yearbook font */}
              <p className="text-left mb-2 font-yearbook">{selectedJob.profile}</p> {/* Added Yearbook font */}
            </div>

            <div className="mb-4">
              <strong className="font-yearbook">Skills Required:</strong> {/* Added Yearbook font */}
              <ul className="list-disc pl-5 mt-2">
                {selectedJob.skills.map((skill, index) => (
                  <li key={index} className="text-left mb-2 font-yearbook">{skill}</li> 
                ))}
              </ul>
            </div>

            <hr className="border-t border-blue-500 my-4" />

            <div className="flex justify-center">
              <Link
                to={`/apply/${encodeURIComponent(selectedJob.category)}/${encodeURIComponent(selectedJob.title)}`}
                className="bg-first-500 text-white py-2 px-4 rounded-lg hover:bg-first-700 transition duration-300 ease-in-out font-yearbook" // Added Yearbook font
              >
                Apply
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
