import React, { useState } from 'react';
import axiosInstance from '../../services/AxiosInstance';

export default function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobilenumber:'',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post('/backend/contact', formData);
      console.log(response);
      setFormData({ name: '',mobilenumber:'', email: '', message: '' }); 
    } catch (error) {
      console.error('There was an error submitting the form!', error);
    }

  };

  return (
    <div>
      <section id="contact" className="py-16 bg-gray-50 pt-28">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold text-gray-800 mb-8 font-yearbook">Get In Touch</h2>
          <p className="text-gray-600 mb-8 font-yearbook">We’d love to hear from you! Reach out to learn more about our work or how you can get involved.</p>
          <form onSubmit={handleSubmit} className="max-w-lg mx-auto bg-white p-8 shadow-lg rounded-lg">
            <div className="grid grid-cols-1 gap-6 mb-6">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Your Name"
                className="p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-shadow font-yearbook"
              />
               <input
                type="text"
                name="mobilenumber"
                value={formData.email}
                onChange={handleChange}
                placeholder="Your Mobile Number"
                className="p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-shadow font-yearbook"
              />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Your Email"
                className="p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-shadow font-yearbook"
              />
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Your Message"
                className="p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-shadow font-yearbook"
                rows="5"
              />
            </div>
            <button
              type="submit"
              className="bg-first-600 text-white px-6 py-3 rounded-full hover:bg-blue-700 transition-colors shadow-lg hover:shadow-xl focus:ring-2 focus:ring-blue-300 focus:outline-none font-yearbook"
            >
              Send Message
            </button>
          </form>
        </div>
      </section>
    </div>
  );
}