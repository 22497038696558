export const jobData = [
  {
    title: 'Ward Boy/Nursing Assistant',
    subTitle: 'wardboy',
    company: 'ESIC Hospital',
    category: 'healthcare',
    location: 'Nashik',
    profile: [
      'Freshers welcome',
      '10th Pass required',
      '8-hour shifts',
    ],
    skills: ['Patient care', 'Basic healthcare support'],

    age: '20 to 38',
    vacancies: 30,
    experience: 'Fresher',
    gender: 'Both Male and Female',
  },
  {
    title: 'Pharmacist',
    subTitle: 'pharmacist',
    company: 'ESIC Hospital',
    category: 'healthcare',
    location: 'Nashik',
    profile: [
      'Diploma or Degree in Pharmacy',
      'Registered with Pharmacy Council',
      '1-3 years of experience',
    ],
    skills: ['Pharmacy', 'Medicine dispensing'],

    age: '20 to 38',
    vacancies: 27,
    experience: '1 to 3 years',
    gender: 'Both Male and Female',
  },
  {
    title: 'Clerk/Computer Operator',
    subTitle: 'clerk',
    company: 'ESIC Administration',
    category: 'administration',
    location: 'Nashik',
    profile: [
      'Degree required',
      'Proficiency in Marathi (30 wpm) and English Typing (40 wpm)',
      'MS-CIT certified',
    ],
    skills: ['Typing', 'Computer operations'],

    age: '20 to 38',
    vacancies: 28,
    experience: '1 to 2 years',
    gender: 'Both Male and Female',
  },
  {
    title: 'Staff Nurse',
    subTitle: 'staffnurse',
    company: 'ESIC Hospital',
    category: 'healthcare',
    location: 'Nashik',
    profile: [
      'GNM/B.Sc Nursing required',
      'Minimum 1 year of experience',
      '8-hour shifts',
    ],
    skills: ['Nursing', 'Patient care'],

    age: '20 to 38',
    vacancies: 9,
    experience: '1 year',
    gender: 'Both Male and Female',
  },
  {
    title: 'Peon/MTS',
    subTitle: 'peon',
    company: 'ESIC Hospital',
    category: 'support',
    location: 'Nashik',
    profile: [
      'Freshers welcome',
      '10th Pass required',
      '8-hour shifts',
    ],
    skills: ['General office support'],

    age: '20 to 38',
    vacancies: 52,
    experience: 'Fresher',
    gender: 'Both Male and Female',
  },
  {
    title: 'Computer Operator',
    subTitle: 'computeroperator',
    company: 'SBI Bank',
    category: 'banking',
    location: 'All Rajasthan',
    profile: [
      '12th Pass required',
      'Basic computer operations',
    ],
    skills: ['Computer operations'],

    age: 'N/A',
    vacancies: 45,
    experience: 'Fresher',
    gender: 'N/A',
  },
  {
    title: 'Various Posts',
    subTitle: 'variousposts',
    company: 'BPCL',
    category: 'corporate',
    location: 'Mumbai',
    profile: [
      '12th Pass required',
      'Roles as per norms',
    ],
    skills: ['General skills'],

    age: 'N/A',
    vacancies: 'N/A',
    experience: 'N/A',
    gender: 'N/A',
  },
  {
    title: 'Clerk',
    subTitle: 'clerk',
    company: 'SIDBI Bank',
    category: 'banking',
    location: 'Mumbai',
    profile: [
      '12th Pass required',
      'Basic banking operations',
    ],
    skills: ['Clerical work'],

    age: 'N/A',
    vacancies: 'N/A',
    experience: 'N/A',
    gender: 'N/A',
  },
  {
    title: 'Computer Operator',
    subTitle: 'computeroperator',
    company: 'Revenue Department',
    category: 'corporate',
    location: 'Korba District, Chhattisgarh',
    profile: [
      '12th Pass required',
      'Proficiency in computer operations',
    ],
    skills: ['Computer operations'],

    age: 'N/A',
    vacancies: 'N/A',
    experience: 'N/A',
    gender: 'N/A',
  },
  {
    title: 'Branch Post Master & Assistant Branch Post Master',
    subTitle: 'postmaster',
    company: 'Indian Post Office',
    category: 'logistics',
    location: 'Madhya Pradesh (Sagar, Sheopur, Shivpuri)',
    profile: [
      '10th, 12th, or Graduation required',
      'Roles as per norms',
    ],
    skills: ['Postal services'],

    age: 'N/A',
    vacancies: 'N/A',
    experience: 'N/A',
    gender: 'N/A',
  },
  {
    title: 'Various Technical and Administrative Roles',
    subTitle: 'technical',
    company: 'Bharat Sanchar Nigam Limited (BSNL)',
    category: 'telecom',
    location: 'Jalgaon, Nashik, Sambhajinagar',
    profile: [
      '12th Pass and Graduation required',
      'Computer knowledge mandatory',
    ],
    skills: ['Technical support', 'Administrative tasks'],

    age: 'N/A',
    vacancies: 'N/A',
    experience: 'N/A',
    gender: 'N/A',
  },
  {
    title: 'Various Jobs',
    subTitle: 'bmc_jobs',
    company: 'Brihanmumbai Municipal Corporation (BMC)',
    category: 'administration',
    location: 'Mumbai (Central, Western, Harbor regions)',
    profile: [
      'Various roles across offices and establishments',
      '10th, 12th, and Graduation required',
    ],
    skills: ['General administrative and operational skills'],
 
    age: '18 to 40',
    vacancies: '1,000',
    experience: 'Freshers and experienced candidates',
    gender: 'Both Male and Female',
  },
  {
    title: 'Clerk/Assistant Manager',
    subTitle: 'clerk_assistant_manager',
    company: 'State Bank of India (SBI)',
    category: 'banking',
    location: 'Maharashtra (Home/Remote)',
    profile: [
      'Clerical and managerial tasks',
      '10th, 12th, and Graduation required',
    ],
    skills: ['Banking operations', 'Administrative support'],

    age: '18 to 40',
    vacancies: 23,
    experience: 'Freshers and experienced candidates',
    gender: 'Both Male and Female',
  },
];
