import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import JobCard from '../parivartanHome/JobCard';
import { jobData } from '../parivartanHome/jobData'; // Ensure jobData is correctly imported
import Search from '../parivartanHome/Search';

const Career = () => {
    const [selectedJob, setSelectedJob] = useState(null);
    const [searchQuery, setSearchQuery] = useState(''); // State for search input

    // Function to open the modal with the selected job data
    const openModal = (job) => {
        console.log('Opening modal for job:', job);  // Debugging log
        setSelectedJob(job);
    };

    // Function to close the modal
    const closeModal = () => {
        setSelectedJob(null);
    };

    // Handler for updating the search query from the Search component
    const handleSearchChange = (query) => {
        setSearchQuery(query);
    };

    // Filter jobs based on the search query
    const filteredJobs = Object.keys(jobData).filter((key) => {
        const job = jobData[key];
        return (
            job.title.toLowerCase().includes(searchQuery.toLowerCase()) || 
            (job.location && job.location.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (job.industry && job.industry.toLowerCase().includes(searchQuery.toLowerCase()))
        );
    });

    return (
        <>
            <div className="mx-3 my-2 rounded-lg bg-gradient-to-r from-blue-700 to-green-700 text-white py-14 text-center mt-20">
                <h1 className="text-2xl md:text-3xl font-extrabold tracking-tight uppercase mb-4">
                    Invest in yourself today for a brighter career tomorrow!
                </h1>
                <p className="text-base md:text-lg font-medium italic">
                    Find what you love and let it guide your career path. 🌟 #DreamJob
                </p>
            </div>

            {/* Job Opening Section */}
            <div className="bg-gray-100 py-12">
                <h2 className="text-2xl md:text-3xl font-semibold mb-8 text-center">
                    Current Job Openings
                </h2>

                {/* Pass handleSearchChange to the Search component */}
                <Search onSearchChange={handleSearchChange} />

                {/* Render filtered jobs */}
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 px-4 md:px-8 lg:px-16 justify-center mx-auto">
                    {filteredJobs.map((key) => (
                        <JobCard key={key} job={jobData[key]} openModal={openModal} />
                    ))}
                </div>
            </div>

            {/* Modal for Selected Job */}
            {selectedJob && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center mt-10">
                    <div className="bg-white rounded-lg p-8 max-w-lg mx-auto relative">
                        <div className="flex justify-between items-center mb-4">
                            <h3 className="text-2xl font-bold">{selectedJob.title}</h3>
                            <button onClick={closeModal} className="text-2xl text-rose-600">
                                &times;
                            </button>
                        </div>
                        <hr className="border-t border-blue-500 my-4" />

                        {/* Profile Required */}
                        {Array.isArray(selectedJob.profile) ? (
                            <div className="mb-4">
                                <strong>Profile Required:</strong>
                                <ul className="list-disc pl-5 mt-2">
                                    {selectedJob.profile.map((profile, index) => (
                                        <li key={index} className="text-left mb-2">{profile}</li>
                                    ))}
                                </ul>
                            </div>
                        ) : (
                            <div className="mb-4">
                                <strong>Profile Required:</strong>
                                <p className="text-left mb-2">{selectedJob.profile}</p>
                            </div>
                        )}

                        {/* Skills Required */}
                        {selectedJob.skills && (
                            <div className="mb-4">
                                <strong>Skills Required:</strong>
                                <ul className="list-disc pl-5 mt-2">
                                    {selectedJob.skills.map((skill, index) => (
                                        <li key={index} className="text-left mb-2">{skill}</li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        <hr className="border-t border-blue-500 my-4" />
                        
                        {/* Apply Button */}
                        <div className="flex justify-center">
                            {selectedJob && selectedJob.subTitle ? (
                                <Link
                                    to={`/apply/${encodeURIComponent(selectedJob.company)}/${encodeURIComponent(selectedJob.subTitle)}`}
                                    className="bg-first-500 text-white py-2 px-4 rounded-lg hover:bg-first-700 transition duration-300 ease-in-out"
                                >
                                    Apply
                                </Link>
                            ) : (
                                <button
                                    disabled
                                    className="bg-gray-400 text-white py-2 px-4 rounded-lg cursor-not-allowed"
                                >
                                    Apply
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Career;
