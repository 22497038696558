import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../../services/AxiosInstance";
import { useLocation, useNavigate } from "react-router-dom";

const McqTestQuestion = () => {
  const location = useLocation();
  const testId = location.state?.id;
  const jobTitle = location.state?.title;
  const navigate = useNavigate();


  // useEffect(() => {

  //   console.log( "mcq question",jobTitle);
    
  // }, []);
  

  // Extract testId and jobTitle from the location state
  // eslint-disable-next-line
  // const [testId, setTestId] = useState(localStorage.getItem('testId') || null);
  // // eslint-disable-next-line
  // const [jobTitle, setJobTitle] = useState(localStorage.getItem('jobTitle') || null);

  // setTestId()
  // setJobTitle()

  // const testId = localStorage.getItem('testId') || null;
  // const jobTitle = localStorage.getItem('jobTitle') || null;

  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(1 * 60); // 3 minutes for demo purposes
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submissionError, setSubmissionError] = useState(null);
  // const [warningCount, setWarningCount] = useState(0);

  const warningCountRef = useRef(0);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        warningCountRef.current += 1;

        if (warningCountRef.current === 1) {
          alert("Warning: Please do not switch tabs during the test.");
        } else if (warningCountRef.current === 2) {
          alert(
            "Warning: Last warning if you change the tabs again your test is not submitted & redirected to home page."
          );
        } else if (warningCountRef.current >= 3) {
          alert("You have switched tabs twice. Redirecting to the homepage.");
          navigate("/"); // Redirect to homepage or any other route
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [navigate]);

  useEffect(() => {
    // Check if testId and jobTitle are null, and navigate to /career if true
    if (testId == null && jobTitle == null) {
      navigate("/");
    }
  }, [testId, jobTitle, navigate]); // Dependencies to re-run the effect if these values change

  useEffect(() => {
    console.log(jobTitle);

    if (jobTitle) {
      axiosInstance
        .get(`/mcq/job/${jobTitle}`)
        .then((response) => {
          const apiQuestions = response.data.map((q) => ({
            id: q.id,
            question: q.question,
            options: [q.optionA, q.optionB, q.optionC, q.optionD],
            answer: q.correctAnswer,
          }));
          console.log(apiQuestions);

          setQuestions(apiQuestions);
          setAnswers(Array(apiQuestions.length).fill(null));
          setIsLoading(false);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error fetching questions:", error);
          setError("Failed to load questions. Please try again later.");
          setIsLoading(false);
        });
    }
  }, [jobTitle]);

  useEffect(() => {
    // Prevent starting a new timer if the form is already submitted
    if (isSubmitted) return;

    // Set up the interval timer
    const timer = setInterval(() => {
      setTimeRemaining((prev) => {
        if (prev <= 1) {
          clearInterval(timer); // Clear interval if time is up
          handleSubmit(); // Trigger the submit function
          return 0; // Ensure timeRemaining is 0
        }
        return prev - 1; // Decrement the time remaining
      });
    }, 1000);

    // Cleanup function to clear interval on component unmount or when isSubmitted changes
    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [isSubmitted]); // Dependency array

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleNext = () => {
    if (selectedOption !== null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = selectedOption;
      setAnswers(newAnswers);

      setSelectedOption(null);
      setCurrentQuestionIndex((prev) => prev + 1);
    }
  };

  const handleSubmit = () => {
    if (selectedOption !== null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = selectedOption;
      setAnswers(newAnswers);

      // Calculate score and additional statistics
      const correctAnswersCount = newAnswers.reduce((count, answer, index) => {
        if (answer === questions[index].answer) {
          return count + 1;
        }
        return count;
      }, 0);

      const totalQuestions = questions.length;
      const calculatedPercentage = (correctAnswersCount / totalQuestions) * 100;

      // Prepare submission data
      const submissionData = {
        totalMcqQuestions: totalQuestions,
        totalMcqAnswers: newAnswers.length,
        correctMcqAnswers: correctAnswersCount,
        percentageMcqAnswers: calculatedPercentage,
        answers: questions.map((q, index) => ({
          question: q.question,
          selectedOption: newAnswers[index],
          answer: q.answer,
        })),
      };

      // console.log(submissionData);

      // POST request to submit answers
      axiosInstance
        .post(`/mcq/answers/${testId}`, submissionData)
        .then((response) => {
          // console.log('Submission successful:', response.data);
          setPercentage(calculatedPercentage);
          setIsSubmitted(true);
          setSubmissionError(null); // Clear any previous submission error
        })
        .catch((error) => {
          console.error("Error submitting answers:", error);
          setSubmissionError(
            "Failed to submit answers. Please try again later."
          );
        });
    }
  };
console.log(percentage);
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${minutes < 10 ? `0${secs}` : secs}`;
  };

  const handleNavigate = () => {
    if (jobTitle === "frontend"
    ) {
      navigate("/upload-document", {
        state: { id: testId, title: jobTitle },
      });
    } else {
      navigate("/upload-document", { state: { id: testId, title: jobTitle } });
    }
  };

  const currentQuestion = questions[currentQuestionIndex] || {};

  return (
    <div className="max-w-3xl m-5 mx-auto p-6 bg-white shadow-xl rounded-lg border border-gray-200 mt-16">
      {isLoading ? (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <button
              disabled
              type="button"
              className="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
            >
              <svg
                aria-hidden="true"
                role="status"
                className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="#1C64F2"
                />
              </svg>
              Loading...
            </button>{" "}
          </div>
        </div>
      ) : error ? (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h1 className="text-2xl font-extrabold mb-4 text-gray-800">
              {error}
            </h1>
            <button
              onClick={() => navigate("/")}
              className="bg-red-600 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-red-700 transition duration-300 ease-in-out"
            >
              Go Back
            </button>
          </div>
        </div>
      ) : !isSubmitted ? (
        <>
          <h1 className="text-4xl font-extrabold text-center mb-6 text-gray-800">
            Question {currentQuestionIndex + 1} of {questions.length}
          </h1>
          <div className="text-center mb-4">
            <p className="text-lg font-medium text-gray-600">
              Time Remaining: {formatTime(timeRemaining)}
            </p>
            {/* <p>{testId}</p> */}
          </div>

          <div className="mb-8">
            <pre
              className="text-2xl font-semibold mb-4 text-gray-700 whitespace-pre-wrap break-words "
              id="questionId"
            >
              {currentQuestion.question}
            </pre>
            <div className="space-y-4">
              {currentQuestion.options?.map((option, index) => (
                <div key={index} className="flex items-center">
                  <input
                    type="radio"
                    id={`option-${currentQuestion.id}-${index}`}
                    name="option"
                    value={option}
                    checked={selectedOption === option}
                    onChange={() => handleOptionChange(option)}
                    className="h-5 w-5 text-blue-500 border-gray-300 focus:ring-blue-500"
                  />
                  <label
                    htmlFor={`option-${currentQuestion.id}-${index}`}
                    className="ml-3 text-lg text-gray-600 cursor-pointer"
                  >
                    {option}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-between items-center">
            {currentQuestionIndex < questions.length - 1 && (
              <button
                onClick={handleNext}
                className="bg-blue-600 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-blue-700 transition duration-300 ease-in-out"
                disabled={selectedOption === null}
              >
                Next
              </button>
            )}
            {currentQuestionIndex === questions.length - 1 && (
              <button
                onClick={handleSubmit}
                className="bg-green-600 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-green-700 transition duration-300 ease-in-out"
                disabled={selectedOption === null}
              >
                Submit
              </button>
            )}

            {submissionError && (
              <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                <div className="bg-white p-6 rounded-lg shadow-lg">
                  <h1 className="text-2xl font-bold text-red-600">
                    {submissionError}
                  </h1>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h1 className="text-4xl font-extrabold mb-6 text-gray-800">
              Test Submitted Successfully
            </h1>
           
            

            <button
              onClick={handleNavigate}
              className="bg-blue-600 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-blue-700 transition duration-300 ease-in-out"
            >
              {jobTitle === "frontend"
                ? "Start Coding Test"
                : "Upload your Document"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default McqTestQuestion;
