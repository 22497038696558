import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import PrivateRoute from "./components/parivartanHome/PrivateRoute";

import CareerPage from "./pages/CareerPage";
import ApplyNowPage from "./pages/ApplyNowPage";
import StartTestpage from "./pages/StartTestpage";
import McqTestPage from "./pages/McqTestPage";
import McqTestQuestion from "./components/mcqTestQuestion/McqTestQuestion";
import CodingTestInstructionPage from "./pages/CodingTestInstructionPage";
import CodingQuestionPage from "./pages/CodingQuestionPage";
import UploadDocumentPage from "./pages/UploadDocumentPage";

// import DetailsTable from "./components/admin/detailsTable/DetailsTable";
import AddMcqPage from "./pages/AddMcqPage";
import AddCodingPage from "./pages/AddCodingPage";
import ShowMcqPage from "./pages/ShowMcqPage";
import ShowCodingQuestionPage from "./pages/ShowCodingQuestionPage";
import UpdateMcqPage from "./pages/UpdateMcqPage";
import UpdateCodingQuestionPage from "./pages/UpdateCodingQuestionPage";
import Contact from "./components/parivartanHome/Contact";
import Services from "./components/parivartanHome/Services";
import HomePage from "./components/parivartanHome/Home";
import AboutUs from "./components/parivartanHome/AboutUs";
import AllJobs from "./components/parivartanHome/AllJobs";
import NavBar from "./components/parivartanHome/NavBar";
import QuestionIcon from "./components/parivartanHome/Question";
import Footer from "./components/parivartanHome/Footer";
import ScrollToTop from "./components/parivartanHome/ScrollToTop";
import LoginPage from "./components/parivartanHome/LoginPage";
import Register from "./components/parivartanHome/Register";
import HiringPage from "./components/parivartanHome/HiringPage";
import Dashboard from "./components/parivartanHome/Dashboard";
import JobDetailsPage from "./components/parivartanHome/JobDetails";

const Layout = ({ children }) => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');

  return (
    <>
      {!isAdminRoute && <NavBar />}
      <main>{children}</main>
      {!isAdminRoute && <Footer />}
      
    </>
  );
};

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <QuestionIcon />

      <Routes>

        <Route path='/career' element={<Layout><CareerPage /></Layout>} />

        <Route path="/apply/:jobCategory/:jobTitle" element={<Layout><ApplyNowPage /></Layout>} />
        <Route path='/start-test' element={<Layout><StartTestpage /></Layout>} />
        <Route path='/Instructions' element={<Layout><McqTestPage /></Layout>} />
        <Route path='/mcq-test' element={<Layout><McqTestQuestion /></Layout>} />
        <Route path='/coding-instruction' element={<Layout><CodingTestInstructionPage /></Layout>} />
        <Route path='/coding-test' element={<Layout><CodingQuestionPage /></Layout>} />
        <Route path='/upload-document' element={<Layout><UploadDocumentPage /></Layout>} />

        <Route path="/" element={<Layout><HomePage /></Layout>} />
        <Route path="/login" element={<Layout><LoginPage /></Layout>} />
        <Route path="/services" element={<Layout><Services /></Layout>} />
        <Route path="/about" element={<Layout><AboutUs /></Layout>} />
        <Route path="/jobs" element={<Layout><AllJobs /></Layout>} />
        <Route path="/contact" element={<Layout><Contact /></Layout>} />

        <Route
            path="/admin/*"
            element={
              <PrivateRoute>
                <Layout>
                  <Dashboard />
                </Layout>
              </PrivateRoute>
            }
          >
            <Route
              path="allcandidates"
              element={
                <PrivateRoute>
                  <Layout>
                    <Register />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="dashboard"
              element={
                <PrivateRoute>
                  <Layout>
                    <HiringPage />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="add_mcq"
              element={
                <PrivateRoute>
                  <Layout>
                    <AddMcqPage />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="job/:jobCategory"
              element={
                <PrivateRoute>
                  <Layout>
                    <JobDetailsPage />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="add-coding"
              element={
                <PrivateRoute>
                  <Layout>
                    <AddCodingPage />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="show-mcq"
              element={
                <PrivateRoute>
                  <Layout>
                    <ShowMcqPage />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="show-coding-question"
              element={
                <PrivateRoute>
                  <Layout>
                    <ShowCodingQuestionPage />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="update-mcq"
              element={
                <PrivateRoute>
                  <Layout>
                    <UpdateMcqPage />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path="update-coding-question"
              element={
                <PrivateRoute>
                  <Layout>
                    <UpdateCodingQuestionPage />
                  </Layout>
                </PrivateRoute>
              }
            />

        </Route>

      </Routes>
    </Router>
  );
};

export default App;
