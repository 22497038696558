import React from 'react';
import img1 from '../images/images.png';
import img2 from '../images/education.jpeg';
import img3 from '../images/i2.jpg';

export default function Services() {
  return (
    <div>
      {/* Projects Section */}
      <section id="projects" className="py-16 bg-gray-50 pt-28">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold text-gray-800 mb-8 font-yearbook">Our Services</h2> {/* Added Yearbook font */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
            {/* Job Placement Card */}
            <div className="bg-white p-6 shadow-lg flex flex-col items-center text-center">
              <img src={img1} alt="Community Development" className="mb-4 rounded-lg w-full h-72 object-center" />
              <h3 className="text-2xl font-semibold mb-2 font-yearbook">Job Placement</h3> {/* Added Yearbook font */}
              <p className="text-gray-600 font-yearbook">We connect qualified candidates with top employers for permanent roles in various industries.</p> {/* Added Yearbook font */}
            </div>

            {/* Staffing Solutions Card */}
            <div className="bg-white p-6 shadow-lg flex flex-col items-center text-center">
              <img src={img3} alt="Healthcare Access" className="mb-4 rounded-lg w-full h-72 object-center" />
              <h3 className="text-2xl font-semibold mb-2 font-yearbook">Staffing Solutions</h3> {/* Added Yearbook font */}
              <p className="text-gray-600 font-yearbook">Our staffing services help businesses find temporary, contract, and permanent employees quickly and efficiently.</p> {/* Added Yearbook font */}
            </div>

            {/* Career Guidance Card */}
            <div className="bg-white p-6 shadow-lg flex flex-col items-center text-center">
              <img src={img2} alt="Education for All" className="mb-4 rounded-lg w-full h-72 object-center" />
              <h3 className="text-2xl font-semibold mb-2 font-yearbook">Career Guidance</h3> {/* Added Yearbook font */}
              <p className="text-gray-600 font-yearbook">We offer expert career counseling and resume optimization services to help job seekers stand out.</p> {/* Added Yearbook font */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
